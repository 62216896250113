




























import { Component, Mixins } from 'vue-property-decorator'
import DrawflowBlockMixin from '@/modules/automations/mixins/drawflowBlock'

@Component({
    name: 'ConditionBlock',
})
export default class extends Mixins(DrawflowBlockMixin) {
    get fixedMode () {
        return this.editor.editor_mode === 'fixed'
    }
}
