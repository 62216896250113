









































import { Component, Mixins } from 'vue-property-decorator'
import DrawflowBlockMixin from '@/modules/automations/mixins/drawflowBlock'

@Component({
    name: 'StartBlock',
})
export default class extends Mixins(DrawflowBlockMixin) {
    openSelectTrigger = false

    get fixedMode () {
        return this.editor.editor_mode === 'fixed'
    }

    selectList (listId: string) {
        if (!this.node) return
        this.node.data.listId = listId
        this.updateNodeData(this.node.data)
    }
}
