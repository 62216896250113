var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsCard',{staticClass:"vs-relative",attrs:{"aspect":_vm.isActive ? 'primary-100' : 'grey-100'}},[_c('div',{staticClass:"vs-h300"},[_c('VsIcon',{attrs:{"size":"8","name":"integration"}}),_vm._v(" Ingresso ")],1),(_vm.node)?_c('div',{staticClass:"vs-mt-4"},[(!_vm.node.data.trigger)?_c('VsButton',{on:{"click":function($event){return _vm.openModal('triggerModal')}}},[_vm._v(" Seleziona evento di partenza ")]):_vm._e(),(_vm.node.data.trigger === 'newContact')?_c('div',[_vm._v(" "+_vm._s(_vm.node.data.trigger)+" "),_c('VsSelect',{staticClass:"vs-mt-4",attrs:{"value":_vm.node.data.listId,"options":[
                    {
                        value: '',
                        label: 'Tutte le liste',
                    },
                    {
                        value: 'listid-1',
                        label: 'Lista brutta',
                    }
                ]},on:{"input":_vm.selectList}})],1):_vm._e()],1):_vm._e(),_c('div',{staticClass:"automation-output-button"},[_c('VsButton',{attrs:{"variant":"icon","size":"small","aspect":"secondary","iconName":"plus"},on:{"click":function($event){return _vm.openModal('selectBlockModal')}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }