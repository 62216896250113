import { VuexModule, Module, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'
import Drawflow, { DrawflowNode } from 'drawflow'

export interface IAutomationsState {
    editor: Drawflow | null
    currentBlock: DrawflowNode | null
    openModalName: string
}

@Module({ dynamic: true, store, name: 'automations', namespaced: true })
class Automations extends VuexModule implements IAutomationsState {
    public editor: Drawflow | null = null
    public currentBlock: DrawflowNode | null = null
    public openModalName = ''
    @Mutation
    public SET_EDITOR (editor: Drawflow | null) {
        this.editor = editor
    }

    @Mutation
    public SET_CURRENT_BLOCK (currentBlock: DrawflowNode | null) {
        this.currentBlock = currentBlock
    }

    @Mutation
    public SET_OPEN_MODAL_NAME (openModalName: string) {
        this.openModalName = openModalName
    }
}

export const AutomationsModule = getModule(Automations)
