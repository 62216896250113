







































































































































import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import Drawflow, { DrawflowConnectionDetail, DrawFlowEditorMode, DrawflowNode } from 'drawflow'
import 'drawflow/dist/drawflow.min.css'
import ConditionBlock from '@/modules/automations/components/drawflowblocks/ConditionBlock/Index.vue'
import DelayBlock from '@/modules/automations/components/drawflowblocks/DelayBlock/Index.vue'
import StartBlock from '@/modules/automations/components/drawflowblocks/StartBlock/Index.vue'
import InfoBlock from '@/modules/automations/components/drawflowblocks/InfoBlock/Index.vue'
import ActionBlock from '@/modules/automations/components/drawflowblocks/ActionBlock/Index.vue'
import { AutomationsModule } from '@/store/modules/automations'
import {
    changeEditorCurvature2,
} from '@/utils/drawflow'
import VsActionCard from '@/components/VsActionCard/Index.vue'

@Component({
    name: 'EditAutomation',
    components: {
        VsContainer,
        VsSectionHeader,
        VsActionCard,
        VsFullTopBarLayout,
    },
})
export default class extends Vue {
    loading = false
    editor: Drawflow | null = null
    $refs!: any

    mobile_item_selec = ''
    mobile_last_move: {
        touches: any[]
    } | null = null

    transform = ''

    initData = {
        drawflow: {
            Home: {
                data: {
                    1: {
                        id: 1,
                        name: 'StartBlock',
                        data: {},
                        class: 'startBlock',
                        html: 'StartBlock',
                        typenode: 'vue',
                        inputs: {},
                        outputs: {
                            output_1: {
                                connections: [],
                            },
                        },
                        pos_x: 481,
                        pos_y: 30,
                    },
                    2: {
                        id: 2,
                        name: 'InfoBlock',
                        data: {},
                        class: 'infoBlock',
                        html: 'InfoBlock',
                        typenode: 'vue',
                        inputs: {},
                        outputs: {},
                        pos_x: 35,
                        pos_y: 35,
                    },
                },
            },
        },
    }

    get openModalName () {
        return AutomationsModule.openModalName
    }

    set openModalName (val: string) {
        AutomationsModule.SET_OPEN_MODAL_NAME(val)
    }

    get currentBlock () {
        return AutomationsModule.currentBlock
    }

    set currentBlock (currentBlock) {
        AutomationsModule.SET_CURRENT_BLOCK(currentBlock)
    }

    mounted () {
        this.editor = new Drawflow(this.$refs.drawflow, Vue, this)
        changeEditorCurvature2(this.editor)

        const props = {
            editor: this.editor,
        }
        this.editor.registerNode('ConditionBlock', ConditionBlock, props, {})
        this.editor.registerNode('DelayBlock', DelayBlock, props, {})
        this.editor.registerNode('StartBlock', StartBlock, props, {})
        this.editor.registerNode('InfoBlock', InfoBlock, props, {})
        this.editor.registerNode('ActionBlock', ActionBlock, props, {})
        this.editor.start()
        this.editor.curvature = 0
        this.editor.reroute_fix_curvature = true
        // this.reroute_curvature_start_end = 0
        // this.reroute_curvature = 0

        this.editor.import(this.initData)
        this.editor.editor_mode = 'edit'

        // Events!
        this.editor.on('nodeCreated', function (id: any) {
            console.log('Node created ' + id)
        })

        this.editor.on('nodeRemoved', function (id: any) {
            console.log('Node removed ' + id)
        })

        this.editor.on('nodeSelected', (id: any) => {
            // if (!this.editor) return
            // console.log('selected')
            // this.currentBlock = this.editor.getNodeFromId(id)
        })

        this.editor.on('nodeUnselected', () => {
            // if (!this.editor) return
            // console.log('nodeUnselected')
            // this.currentBlock = null
        })

        this.editor.on('nodeMoved', (id: any) => {
            // console.log('Node moved ' + id)
            // if (!this.editor || !this.currentBlock) return
            // const movedNode = this.editor.getNodeFromId(id)
            // console.log('yeeee', this.currentBlock.pos_x - movedNode.pos_x, this.currentBlock.pos_y - movedNode.pos_y)

            // this.translateChildBlocks(this.currentBlock, movedNode.pos_x - this.currentBlock.pos_x, movedNode.pos_y - this.currentBlock.pos_y)
            // this.currentBlock = movedNode
        })

        this.editor.on('moduleCreated', function (name: any) {
            console.log('Module Created ' + name)
        })

        this.editor.on('moduleChanged', function (name: any) {
            console.log('Module Changed ' + name)
        })

        this.editor.on('connectionCreated', function (connection: any) {
            console.log('Connection created')
            console.log(connection)
        })

        this.editor.on('connectionRemoved', function (connection: any) {
            console.log('Connection removed')
            console.log(connection)
        })

        this.editor.on('mouseMove', function (position: any) {
            // console.log('Position mouse x:' + position.x + ' y:' + position.y)
        })

        this.editor.on('zoom', function (zoom: any) {
            console.log('Zoom level ' + zoom)
        })

        this.editor.on('translate', (position: any) => {
            console.log('Translate x:' + position.x + ' y:' + position.y)
        })

        this.editor.on('addReroute', function (id: any) {
            console.log('Reroute added ' + id)
        })

        this.editor.on('removeReroute', function (id: any) {
            console.log('Reroute removed ' + id)
        })
    }

    positionMobile (ev: any) {
        this.mobile_last_move = ev
    }

    allowDrop (ev: any) {
        ev.preventDefault()
    }

    drag (ev: any) {
        if (ev.type === 'touchstart') {
            this.mobile_item_selec = ev.target.closest('.drag-drawflow').getAttribute('data-node')
        } else {
            ev.dataTransfer.setData('node', ev.target.getAttribute('data-node'))
        }
    }

    drop (ev: any) {
        if (!document) return
        if (ev.type === 'touchend') {
            const clientX = this.mobile_last_move?.touches[0].clientX || 0
            const clientY = this.mobile_last_move?.touches[0].clientY || 0
            const parentdrawflow = document.elementFromPoint(clientX, clientY)?.closest('#drawflow')
            if (parentdrawflow) {
                this.addNodeToDrawFlow(this.mobile_item_selec, clientX, clientY)
            }
            this.mobile_item_selec = ''
        } else {
            ev.preventDefault()
            const data = ev.dataTransfer.getData('node')
            this.addNodeToDrawFlow(data, ev.clientX, ev.clientY)
        }
    }

    addNodeToDrawFlow (name: string, pos_x: number, pos_y: number) {
        if (!this.editor) return false
        if (this.editor.editor_mode === 'fixed') {
            return false
        }
        pos_x = pos_x * (this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom)) - (this.editor.precanvas.getBoundingClientRect().x * (this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom)))
        pos_y = pos_y * (this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom)) - (this.editor.precanvas.getBoundingClientRect().y * (this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom)))
        // let html = ''
        switch (name) {
            case 'ConditionBlock':
                this.editor.addNode('ConditionBlock', 1, 2, pos_x, pos_y, 'conditionBlock', {}, 'ConditionBlock', 'vue')
                break
            case 'DelayBlock':
                this.editor.addNode('DelayBlock', 1, 1, pos_x, pos_y, 'delayBlock', {}, 'DelayBlock', 'vue')
                break
            case 'ActionBlock':
                this.editor.addNode('ActionBlock', 1, 1, pos_x, pos_y, 'actionBlock', {}, 'ActionBlock', 'vue')
                break
            default:
        }
    }

    // showpopup (e: any) {
    //     if (!this.editor) return
    //     e.target.closest('.drawflow-node').style.zIndex = '9999'
    //     e.target.children[0].style.display = 'block'
    //     // document.getElementById("modalfix").style.display = "block";

    //     // e.target.children[0].style.transform = 'translate('+translate.x+'px, '+translate.y+'px)';
    //     this.transform = this.editor.precanvas.style.transform
    //     this.editor.precanvas.style.transform = ''
    //     this.editor.precanvas.style.left = this.editor.canvas_x + 'px'
    //     this.editor.precanvas.style.top = this.editor.canvas_y + 'px'
    //     console.log(this.transform)

    //     // e.target.children[0].style.top  =  -editor.canvas_y - editor.container.offsetTop +'px';
    //     // e.target.children[0].style.left  =  -editor.canvas_x  - editor.container.offsetLeft +'px';
    //     this.editor.editor_mode = 'fixed'
    // }

    // closemodal (e: any) {
    //     if (!this.editor) return
    //     e.target.closest('.drawflow-node').style.zIndex = '2'
    //     e.target.parentElement.parentElement.style.display = 'none'
    //     // document.getElementById("modalfix").style.display = "none";
    //     this.editor.precanvas.style.transform = this.transform
    //     this.editor.precanvas.style.left = '0px'
    //     this.editor.precanvas.style.top = '0px'
    //     this.editor.editor_mode = 'edit'
    // }

    exportData () {
        if (!this.editor) return
        console.log(this.editor.export())
    }

    changeMode (option: DrawFlowEditorMode) {
        if (!this.editor) return
        this.editor.editor_mode = option
    }

    newContactEvent () {
        if (!this.currentBlock) return
        this.updateNodeData({
            trigger: 'newContact',
            listId: '',
        })
        this.openModalName = ''
    }

    updateNodeData (data: any) {
        if (!this.editor || !this.currentBlock) return
        this.editor.updateNodeDataFromId(this.currentBlock.id, data)
    }

    addBlock (name: string, output: string) {
        if (!this.editor) return
        let pos_x = 0
        let pos_y = 0
        const offset = 60
        let hasChild = false
        if (this.currentBlock) {
            const node = document.getElementById('node-' + this.currentBlock.id)
            const translateY = node?.offsetHeight ? node.offsetHeight + offset : offset
            if (node) {
                pos_x = this.currentBlock.pos_x
                pos_y = this.currentBlock.pos_y + translateY
            }
            hasChild = this.currentBlock.outputs.output_1 && this.currentBlock.outputs.output_1.connections && this.currentBlock.outputs.output_1.connections.length > 0
        }
        let id: any = ''
        switch (name) {
            case 'ConditionBlock':
                id = this.editor.addNode('ConditionBlock', 1, 2, pos_x, pos_y, 'conditionBlock', {}, 'ConditionBlock', 'vue')
                break
            case 'DelayBlock':
                id = this.editor.addNode('DelayBlock', 1, 1, pos_x, pos_y, 'delayBlock', {}, 'DelayBlock', 'vue')
                break
            case 'ActionBlock':
                id = this.editor.addNode('ActionBlock', 1, 1, pos_x, pos_y, 'actionBlock', {}, 'ActionBlock', 'vue')
                break
            default:
        }

        setTimeout(() => {
            if (id && this.currentBlock) {
                if (!this.editor) return
                if (hasChild) {
                    const node = document.getElementById('node-' + id)
                    const translateY = node?.offsetHeight ? node.offsetHeight + offset : offset
                    this.translateChildBlocks(this.currentBlock, 0, translateY)
                    const connection = this.currentBlock.outputs.output_1.connections[0]
                    if (connection) {
                        this.editor.removeSingleConnection(this.currentBlock.id, connection.node, 'output_1', 'input_1')
                        this.editor.addConnection(id, connection.node, 'output_1', `input_1`)
                    }
                }
                this.editor.addConnection(this.currentBlock.id, id, output, `input_1`)
            }
            this.openModalName = ''
        }, 100)
    }

    translateChildBlocks (block: DrawflowNode, translateX: number, translateY: number) {
        if (!this.editor) return
        for (const key in block.outputs) {
            const output = block.outputs[key]
            if (!output.connections) continue
            for (const connection of output.connections) {
                const block = this.editor.getNodeFromId(connection.node)
                const node = document.getElementById('node-' + block.id)
                if (!node || !block) continue
                this.editor.drawflow.drawflow.Home.data[block.id].pos_x = this.editor.drawflow.drawflow.Home.data[block.id].pos_x + translateX
                this.editor.drawflow.drawflow.Home.data[block.id].pos_y = this.editor.drawflow.drawflow.Home.data[block.id].pos_y + translateY
                node.style.left = this.editor.drawflow.drawflow.Home.data[block.id].pos_x + 'px'
                node.style.top = this.editor.drawflow.drawflow.Home.data[block.id].pos_y + 'px'
                this.editor.updateConnectionNodes('node-' + block.id)
                this.translateChildBlocks(block, translateX, translateY)
            }
        }
    }
}
