







































import { Component, Mixins } from 'vue-property-decorator'
import DrawflowBlockMixin from '@/modules/automations/mixins/drawflowBlock'

@Component({
    name: 'DelayBlock',
})
export default class extends Mixins(DrawflowBlockMixin) {
    setConnection () {
        return ''
    }
}
