import { AutomationsModule } from '@/store/modules/automations'
import Drawflow, { DrawflowNode } from 'drawflow'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
    name: 'DrawflowBlockMixin',
})
export default class extends Vue {
    @Prop({ default: null, required: false }) editor!: Drawflow
    id = ''
    node: DrawflowNode | null = null
    isActive = false

    get blockName () {
        if (!this.node) return ''
        return this.node.name
    }

    get openModalName () {
        return AutomationsModule.openModalName
    }

    set openModalName (val: string) {
        AutomationsModule.SET_OPEN_MODAL_NAME(val)
    }

    get currentBlock () {
        return AutomationsModule.currentBlock
    }

    set currentBlock (currentBlock) {
        AutomationsModule.SET_CURRENT_BLOCK(currentBlock)
    }

    @Watch('editor.drawflow.drawflow.Home.data', { deep: true })
    setNode () {
        this.node = this.editor.drawflow.drawflow.Home.data[this.id]
    }

    mounted () {
        this.$nextTick(() => {
            this.id = this.$el.parentElement?.parentElement?.id.slice(5) || ''
            this.node = this.editor.getNodeFromId(this.id)
        })

        this.editor.on('nodeSelected', (id: any) => {
            this.isActive = this.id === id
        })
        this.editor.on('nodeUnselected', () => {
            this.isActive = false
        })
    }

    updateNodeData (data: any) {
        this.editor.updateNodeDataFromId(this.id, data)
    }

    removeConnection () {
        if (!this.node || !this.node.inputs || !this.node.inputs.input_1) return
        for (const connection of this.node.inputs.input_1.connections) {
            this.editor.removeSingleConnection(connection.node, this.node.id, connection.input, 'input_1')
        }
    }

    openModal (name: string) {
        this.currentBlock = this.node
        this.openModalName = name
    }
}
