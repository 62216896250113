export const changeEditorCurvature = (editor: any) => {
    editor.createCurvature = (start_x: number, start_y: number, end_x: number, end_y: number, curvature_value: number, type: string) => {
        const center_y = ((end_y - start_y) / 2) + start_y
        const default_round_radius = 10
        const round_radius = Math.min(default_round_radius, Math.abs(start_x - end_x), Math.abs(start_y - end_y))

        const isRight: any = end_x > start_x
        const isDown: any = end_y > start_y
        return `M ${start_x} ${start_y}
                L ${start_x} ${isDown ? center_y - round_radius : center_y + round_radius}
                A ${round_radius} ${round_radius} 0 0 ${isRight ^ !isDown as any ? 0 : 1} ${isRight ? start_x + round_radius : start_x - round_radius} ${center_y}
                L ${isRight ? end_x - round_radius : end_x + round_radius} ${center_y}
                A ${round_radius} ${round_radius} 0 0 ${isRight ^ !isDown as any ? 1 : 0} ${end_x} ${isDown ? center_y + round_radius : center_y - round_radius}
                L ${end_x} ${end_y}`
    }
}

export const changeEditorCurvature2 = (editor: any) => {
    editor.createCurvature = (start_pos_x: number, start_pos_y: number, end_pos_x: number, end_pos_y: number, curvature_value: string) => {
        const offset = 20
        const isDown: any = end_pos_y - offset > start_pos_y + offset

        if (isDown) {
            const center_y = ((end_pos_y - start_pos_y) / 2) + start_pos_y
            return `M ${start_pos_x} ${start_pos_y}
                    L ${start_pos_x} ${center_y}
                    L ${end_pos_x} ${center_y}
                    L ${end_pos_x} ${end_pos_y}`
        } else {
            const center_x = ((end_pos_x - start_pos_x) / 2) + start_pos_x
            return `M ${start_pos_x} ${start_pos_y}
                    L ${start_pos_x} ${start_pos_y + offset}
                    L ${center_x} ${start_pos_y + offset}
                    L ${center_x} ${end_pos_y - offset}
                    L ${end_pos_x} ${end_pos_y - offset}
                    L ${end_pos_x} ${end_pos_y}`
        }
    }
}
